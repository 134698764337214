import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const CleanPalette = {
  Ice: '#EBF1F2',

  Blue: '#58B1CB',
  DarkBlue: '#213E51',
  DarkerBlue: '#112D40',
  NeutralDarkest: '#434343',
  NeutralDarker: '#707070',
  NeutralDark: '#7D838B',
  Neutral: '#b3babb',
  NeutralLighter: '#DCE5EB',

  Green: '#18CF65',
  GreenLight: '#30EA76',
  GreenHighlight: '#40F27E',
  Orange: '#FF8956',
  OrangeLight: '#F69166',
  Red: '#E6213F',
  White: '#ffffff',
  AlmostWhite: '#f5fafc',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: CleanPalette.Blue,
  PrimaryTint: CleanPalette.DarkBlue,
  PrimaryContrast: CleanPalette.White,

  Secondary: CleanPalette.DarkBlue,
  SecondaryTint: CleanPalette.DarkerBlue,
  SecondaryContrast: CleanPalette.White,

  Accent: CleanPalette.Green,
  AccentTint: CleanPalette.GreenLight,
  AccentContrast: CleanPalette.White,

  Gradient: CleanPalette.Blue,
  GradientTint: CleanPalette.DarkBlue,
  GradientContrast: CleanPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: CleanPalette.DarkerBlue,
  BodyText: CleanPalette.DarkerBlue,
  MutedText: '#738794',
  HighlightedText: CleanPalette.DarkerBlue,
  LinkText: CleanPalette.Blue,
  ErrorText: CleanPalette.Red,
  DisabledText: CleanPalette.Neutral,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: CleanPalette.White,
    Foreground: CleanPalette.DarkerBlue,
    Dimmed: createTransparentColor(CleanPalette.DarkBlue, 0.15),
  },
  Nested: {
    Background: CleanPalette.AlmostWhite,
    Foreground: CleanPalette.DarkerBlue,
    Dimmed: createTransparentColor(CleanPalette.DarkBlue, 0.15),
  },
  Disabled: {
    Background: CleanPalette.NeutralDark,
    Foreground: CleanPalette.Neutral,
    Dimmed: createTransparentColor(CleanPalette.NeutralDarker, 0.15),
  },
  Floating: {
    Background: CleanPalette.Ice,
    Foreground: CleanPalette.DarkerBlue,
    Dimmed: createTransparentColor(CleanPalette.DarkBlue, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: CleanPalette.Green,
  SuccessContrast: CleanPalette.White,

  Info: CleanPalette.Green,
  InfoContrast: CleanPalette.White,

  Attention: CleanPalette.Orange,
  AttentionContrast: CleanPalette.White,

  Danger: CleanPalette.Red,
  DangerContrast: CleanPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '8px',
  Large: '24px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `0 1px 2px -1px var(--shadow-color, ${createTransparentColor(CleanPalette.DarkerBlue, 0.1)})`,
  Level2: `0 3px 6px -2px var(--shadow-color, ${createTransparentColor(CleanPalette.DarkerBlue, 0.1)})`,
  Level3: `0 8px 12px var(--shadow-color, ${createTransparentColor(CleanPalette.DarkerBlue, 0.1)}), 0 3px 6px -1px var(--shadow-color, ${createTransparentColor(CleanPalette.DarkerBlue, 0.1)})`,
};

const General: ColorDefinition['General'] = {
  Bonus: CleanPalette.Blue,
  BonusContrast: CleanPalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: CleanPalette.Ice,
  BrandBorder: Primitive.Primary,
  BrowserTheme: CleanPalette.Ice,
  LoadingColor: Primitive.Primary,
  Overlay: CleanPalette.NeutralLighter + 'CC',
  Sidebar: {
    Background: CleanPalette.DarkerBlue,
    Foreground: CleanPalette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: CleanPalette.DarkerBlue,
  Text: CleanPalette.DarkBlue,
  TextHighlight: CleanPalette.Green,

  Button: {
    Solid: CleanPalette.Green,
    Rest: CleanPalette.Green,
    Hover: CleanPalette.GreenLight,
    Active: CleanPalette.GreenHighlight,
    Text: CleanPalette.White,
  },
};
export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
